<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="$t('form.type.label')"
                label-for="type"
                :state="errors && errors.type ? false : null"
              >
                <v-select
                  id="type"
                  v-model="item.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="stickerTypeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="type"
                />
                <b-form-invalid-feedback v-if="errors && errors.type">
                  {{ errors.type[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              v-if="item.type === 'default'"
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.background.label')"
                label-for="background"
              >
                <b-form-input
                  id="background"
                  v-model="item.background"
                  :state="errors && errors.background ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.background">
                  {{ errors.background[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              v-if="item.type === 'default'"
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.color.label')"
                label-for="color"
              >
                <b-form-input
                  id="color"
                  v-model="item.color"
                  :state="errors && errors.color ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.color">
                  {{ errors.color[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              v-if="item.type === 'icon'"
              cols="12"
              md="4"
            >
              <!-- media -->
              <b-media no-body>
                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                      height="80"
                    />
                  </b-link>
                  <!--/ avatar -->

                  <!-- reset -->
                  <b-button
                    v-if="item.avatar"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="item.avatar = null"
                  >
                    {{ $t('general.reset') }}
                  </b-button>
                  <!--/ reset -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <InitFileManager
                    field="avatar"
                    type="sticker_icon"
                    :multiple="false"
                    @fm-selected="selectedAvatar"
                  />

                  <b-row
                    v-if="errors && errors.avatar"
                  >
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors.avatar[0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="errors && errors['avatar.type']"
                  >
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors['avatar.type'][0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>

                </b-media-body>
              </b-media>
              <!--/ media -->
            </b-col>
          </b-row>

          <b-tabs pills>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="item.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'stickers-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/stickers/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'stickers-index' })
        }
      })
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        background: this.item.background,
        color: this.item.color,
        avatar: this.item.avatar,
        type: this.item.type,
      }

      this.$http.put(`/api/stickers/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'stickers-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        background: item.background,
        color: item.color,
        avatar: this.$options.filters.singleMedia(item.avatar),
        type: item.type,
        title: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
